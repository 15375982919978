@font-face {
  font-family: Mokoto;
  src: url("Mokoto Glitch.843f8c31.ttf");
}

@font-face {
  font-family: Mokoto;
  src: url("Mokoto Glitch.843f8c31.ttf");
}

/*# sourceMappingURL=index.248fcf15.css.map */
